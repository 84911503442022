$theme_bg: #363F48;
$theme_color: #ff447c;
$default_transition: all .2s ease-in-out;
$theme_color_2: #4bc6ba;

body {
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

.custom-navbar {
  background: rgba(54, 63, 72, 0.95);
  position: fixed;
  width: 100%;
  z-index: 99;
  border-bottom: 1px solid gray;
  top: 0;

  .logo {
    width: 230px;
  }

  .custom-input-group {
    width: 85%;
    border: 1px solid #3b444f;
    border-radius: 5px;

    .input-group-text {
      font-size: 14px;
      background: transparent;
      color: white;
      border: none;
    }

    input {
      font-size: 13px;
      background: transparent;
      border: none;
      color: white;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .nav-item {
    a {
      color: white;
      position: relative;
      transition: $default_transition;

      &:hover {
        color: gray;
      }
    }

    .add-listing-button {
      background: white;
      color: $theme_color;
      font-size: 12px;
      padding: 10px 15px;
      border-radius: 3px;

      &:hover {
        background: $theme_color;
        color: white;
      }
    }
  }

  .dropdown-menu {
    border: none;
    box-shadow: 0 3px 15px black;
    padding: 15px 25px;
    min-width: 175px;

    li {
      border-bottom: 1px solid silver;

      &:last-of-type {
        border-bottom: none;
      }

      .dropdown-item {
        color: #3b444f !important;
        font-size: 14px;
        padding: 7px 0;

        &:hover {
          background: transparent;
          padding-left: 10px;
        }
      }
    }
  }
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.banner {
  width: 100vw;
  height: 100vh;
  position: relative;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overly {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(59, 68, 79, 0.75);
  }

  .content {
    position: absolute;
    left: 50%;
    top: 50%;
    min-width: 60%;
    transform: translate(-50%, -50%);

    h1, p {
      color: white;
      text-align: center;
    }

    .search-area {
      background: white;
      padding: 20px;
      border-radius: 5px;

      .form-floating {
        label {
          color: $theme_color;
        }
      }

      .form-control, .form-select {
        border: none;
        border-bottom: 1px solid silver;
        border-radius: 0;

        &:focus {
          box-shadow: none !important;
        }
      }

      .search-button {
        border: none;
        background: $theme_color;
        color: white;
        padding: 15px;
        width: 100%;
        border-radius: 3px;
        font-size: 14px;
      }
    }

    .icon-area {
      margin-top: 50px;

      .icon-item {
        background: rgba(0, 0, 0, 0.30);
        text-align: center;
        padding: 25px 10px;
        color: white;
        cursor: pointer;
        transition: $default_transition;
        height: 100%;

        &:hover {
          padding-top: 15px;
          background: rgba(0, 0, 0, 0.40);
        }

        i {
          font-size: 30px;
        }

        p {
          font-size: 13px;
          margin-top: 5px;
          margin-bottom: 0;
        }
      }
    }
  }
}

.categories, .feature-area, .city-area {
  p {
    text-align: center;
    color: $theme_color;
    text-transform: uppercase;
  }

  h4 {
    text-align: center;
    font-size: 36px;
    text-transform: uppercase;
  }
}


.category-item {
  position: relative;
  cursor: pointer;
  transition: $default_transition;

  &:hover .overly {
    opacity: 1;
  }

  .overly {
    background-image: linear-gradient(90deg, rgba(255, 0, 255, 0.75), rgba(0, 255, 255, 0.75));
    opacity: .5;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: $default_transition;
  }

  .content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    text-shadow: 2px 2px 2px black;

    i {
      font-size: 40px;
      margin-bottom: 50px;
    }
  }

  img {
    width: 100%;
    aspect-ratio: 1.25/1;
    object-fit: cover;
  }
}

.section-gap {
  margin-top: 125px;
  margin-bottom: 125px;
}

.section-gap-top {
  margin-top: 125px;
}

.feature-area {
  .feature-item {
    border: 1px solid silver;
    border-radius: 3px;
    position: relative;
    overflow-y: hidden;

    .badge-area {
      position: absolute;
      top: 10px;
      left: 10px;

      button {
        background: transparent;
        border: 1px solid white;
        color: white;
        padding: 4px 10px;
        font-size: 12px;
        margin-right: 5px;
        transition: $default_transition;

        &:hover {
          background: $theme_color;
          color: white;
        }
      }
    }

    .feature-image {
      width: 100%;
      height: 375px;
      object-fit: cover;
    }

    .overly {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-image: linear-gradient(90deg, rgb(144, 2, 144), rgb(2, 114, 114));
      opacity: .5;
    }

    .content {
      background: white;
      padding: 20px 20px 0 20px;
      position: absolute;
      bottom: -80px;
      transition: $default_transition;
      cursor: pointer;
      width: 100%;
      min-height: 225px;

      &:hover {
        bottom: 65px;
      }

      .content-data {
        position: relative;
        text-align: center;

        i {
          color: $theme_color;
        }

        a {
          color: gray;
          text-decoration: none;
          font-size: 13px;

          &:hover {
            color: $theme_color;
          }
        }

        address {
          font-size: 13px;
          color: gray;
          margin: 0;
        }

        .content-image {
          img {
            position: absolute;
            width: 75px;
            height: 75px;
            object-fit: cover;
            border-radius: 50%;
            top: -57px;
            left: 50%;
            transform: translateX(-50%);
            border: 3px solid $theme_color_2;
          }
        }

        h4 {
          font-size: 16px;
          text-transform: uppercase;
          padding-top: 35px;
        }

        h6 {
          font-size: 14px;
          text-transform: uppercase;
          text-align: center;
          font-weight: normal;
          color: gray;
          padding: 15px 0;
        }
      }

    }

    .content-footer {
      background: white;
      padding: 20px;
      position: absolute;
      bottom: 0;
      width: 100%;

      span {
        font-size: 13px;
        color: gray;
      }

      button {
        background: white;
        border: 1px solid silver;
        border-radius: 50%;
        padding: 3px 6px;
        font-size: 11px;
        margin-left: 5px;
        color: $theme_color;
      }
    }
  }
}

.explore-button {
  background: white;
  color: $theme_color;
  border-radius: 5px;
  border: 1px solid $theme_color;
  padding: 15px 25px;
  transition: $default_transition;

  &:hover {
    background: $theme_color;
    color: white;
  }
}

.slick-slide {
  padding: 10px;
}

.slick-prev:before, .slick-next:before {
  color: $theme_color_2 !important;
  font-size: 28px !important;
  opacity: .5 !important;
}

.slick-prev:hover:before, .slick-next:hover:before {
  opacity: 1 !important;
}

.slick-dots li button:before {
  border: 1px solid silver !important;
  border-radius: 50% !important;
  font-size: 12px !important;
}

.slick-dots li.slick-active button:before {
  color: $theme_color_2 !important;
  border: 1px solid $theme_color !important;
}

.parallax-area {
  background-image: url("./../Image/service_photo/parallax.JPG");
  width: 100%;
  height: 520px;
  background-color: #0d3a34;
  background-blend-mode: multiply;
  background-attachment: fixed;
  background-size: cover;

  .content {
    padding-top: 200px;

    h1, p {
      color: white;
    }

    button {
      background: white;
      color: $theme_color;
      border: 2px solid $theme_color;
      padding: 15px 40px;
      border-radius: 5px;
      transition: $default_transition;

      &:hover {
        background: $theme_color;
        color: white;
      }
    }
  }
}

.city-area {
  .my-card {
    .my-card-header {
      h1 {
        color: $theme_color;
      }

      h6 {
        color: darkcyan;
      }
    }

    .my-card-body {
      width: 100%;
      overflow: hidden;

      &:hover .card-img-top {
        transform: scale(1.2);
      }

      .card-img-top {
        width: 100%;
        height: 350px;
        object-fit: cover;
        transition: .3s all ease-in-out;
      }
    }
  }
}

.testimonial {
  .image-area {
    display: flex;
    justify-content: center;

    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 50%;
      border: 5px solid $theme_color_2;
      margin: 0 10px;
      cursor: pointer;
      transition: $default_transition;
    }

    .active {
      border: 5px solid $theme_color;
    }
  }

  .content-area {
    width: 75%;
    text-align: center;
    margin: 75px auto 0;
    transition: $default_transition;

    h5 {
      color: $theme_color;
    }

    .name {
      color: $theme_color_2;
    }
  }
}

.clients-area {
  .slick-slide {
    img {
      max-width: 100%;
      aspect-ratio: 4/3;
      object-fit: cover;
    }
  }
}

.footer {
  margin-top: 150px;
}

.nav-gap {
  padding-top: 150px;
}

.text-pink {
  text-align: center;
  color: #ff447c;
  text-transform: uppercase;
}

.section-headline {
  text-align: center;
  font-size: 36px;
  text-transform: uppercase;
}

.details-cover-photo {
  position: relative;

  .cover-content {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    .cover-content-area {
      background: rgba(0, 0, 0, 0.5);
      width: fit-content;
      margin-right: 0;
      margin-left: auto;
      padding: 10px 15px;
      border-radius: 5px 5px 0 0;

      a {
        color: white;
        text-decoration: none;
        padding-right: 15px;
        font-size: 14px;
        font-weight: lighter;
      }

      button {
        background: transparent;
        border: 1px solid white;
        border-radius: 5px;
        color: white;
        margin-left: 15px;
        font-weight: lighter;
        padding: 5px 10px;
      }
    }
  }

  img {
    width: 100%;
    height: 450px;
    object-fit: cover;
  }
}

.details-page-header {
  border-bottom: 1px solid silver;
  padding-bottom: 15px;

  .details-page-nav {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    li {
      list-style: none;

      &:after {
        content: '|';
        padding-left: 25px;
        color: gray;
      }

      &:last-of-type:after {
        content: '';
      }

      a {
        color: gray;
        text-decoration: none;

        &:hover {
          color: #ff447c;
        }
      }
    }
  }

  .details-page-header-area {
    position: relative;
    width: 200px;
  }

  .details-cover {
    width: 150px;
    position: absolute;
    height: 150px;
    object-fit: cover;
    top: -40px;
    left: -13px;
  }

  h5 {
    font-size: 17px;
    margin-top: 40px;
  }

  p {
    font-size: 14px;
    color: gray;
  }
}

.text-sm {
  font-size: 14px;
  text-align: justify;
  color: gray;
}

.request-button {
  background: #ff447c;
}

.details-photo-item {
  width: 45% !important;
  margin: 5px;
}

.explore-filter-area {
  text-align: center;

  button {
    border: 1px solid #ff447c;
    margin: 4px;
    padding: 15px;
    border-radius: 10px 0 10px 0;
    color: #ff447c;
    transition: .3s all ease-in-out;

    &:hover {
      color: white;
      background: #ff447c;
    }
  }

  //.active{
  //  color: white;
  //  background: #ff447c;
  //}
}


.theme-button {
  border: 1px solid #ff447c;
  margin: 4px;
  padding: 5px;
  border-radius: 5px;
  color: #ff447c;
  transition: .3s all ease-in-out;

  &:hover {
    color: white;
    background: #ff447c;
  }
}


.nav-link.selected {
  color: #ff447c !important;
}

.language-select {
  position: absolute;
  right: 10px;
  width: 65px;
}

.section-p {
  text-align: center;
  color: #ff447c;
  text-transform: uppercase;
}

.my-card {
  box-shadow: 0 0 20px silver;
  transition: .3s all ease-in-out;
  border: none;

  .my-card-header {
    background: rgba(255, 0, 0, 0.1) !important;
    border: none;

    h4 {
      color: #ff447c;
      text-align: center;
    }
  }

  .card-body {
    h1 {
      color: #ff447c;
      text-align: center;
      margin: 15px 0;
    }

    ul {
      padding: 0;

      li {
        list-style: none;
        text-align: center;
        padding: 5px 0;
      }
    }

    button {
      background: #ff447c;
      color: white;
      border: 2px solid #ff447c;
      padding: 5px;
      text-align: center;
      transition: .3s all ease-in-out;

      &:hover {
        background: white;
        color: #ff447c;
      }
    }
  }

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
}

.form-control, .form-select {
  &:focus {
    box-shadow: none !important;
  }
}

.day-input-section {
  padding: 5px 10px;
  border: 1px solid silver;
  border-radius: 10px;
}


.home-blog {
  text-align: left;

  p small {
    color: silver;
  }

  p {
    text-align: justify;
    font-size: 16px;
  }

  h4 {
    color: #ff447c;
    padding: 5px 0;
    text-decoration: none;
  }

  img {
    aspect-ratio: 3/2;
    object-fit: cover;
  }
}

.listing-card-header {
  background: rgba(0, 139, 139, 0.50);
}

.listing-card {
  border: 1px solid rgba(0, 139, 139, .75);
}

.preview-image {
  width: 85px;
}

//.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
//  height: 250px!important;
//}

.text-justify {
  text-align: justify !important;
}

.small-table {
  font-size: 14px;

  td, th {
    vertical-align: middle;
    font-size: 13px;
  }
}

.dashboard-menu {
  ul {
    text-align: center;
    border: 1px solid silver;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 25px;
    background: #ffdbf6;

    li {
      display: inline;
      padding: 0 10px;

      a, button {
        color: black;
        font-weight: bolder;
        transition: .2s all ease-in-out;
        border: none;
        background: none;

        &:hover {
          color: $theme-color;
        }
      }
    }
  }
}

.text-about {
  text-transform: unset !important;
}

label {
  position: relative;
}

.login-error-msg {
  position: absolute;
  color: red;
}

.google-map {
  width: 100%;
}

.place-card {
  text-align: center;
  padding: 25px;

  i {
    color: $theme-color;
  }

  h4 {
    margin-top: 10px;
  }
}

.link-type {
  h4 {
    color: $theme-bg;
  }
}

.service-card {
  text-align: center;

  i {
    color: $theme-color;
  }

  p {
    color: $theme-color;
  }
}

.animation {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.animation.in {
  opacity: 1;
}

.animation.out {
  opacity: 0;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.loader-container {
  width: 100%;
  min-height: 350px;
  position: relative;

  .image-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    h4 {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 20px;
      color: darkcyan;
    }
  }
}

.listing-social {
  text-align: center;
  i {
    font-size: 28px;
    margin-right: 30px;
    text-align: center;
    color: $theme-color;
    &:hover{
      color: darkcyan;
    }
  }
}
.cursor-pointer{
  cursor: pointer;
}